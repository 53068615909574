 <template>
  <div class="mt-5 mb-5 mettings mettings-preview">
    <b-container class="bv-example-row">
      <b-row>

        <b-col cols="md-4 sm-12">
          <b-card-group class="card-bigger">
            <b-card>
        
                <h2 class="mb-1" style="margin-top: 10%" align="center" v-if="meetings.user">
                  {{ meetings.user.name }}
                </h2>
              <br />
              <h2 v-if="meetings.user"><b>{{ meetings.meetingName }}</b></h2>
              <p v-if="meetings.description">
                {{ meetings.description }}
              </p>
              <p v-else>No Description Yet.</p>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col
          ><b-card class="card-bigger"  header="Meeting parameters" align="center">
            <b-row>
              <b-col class="datepicker-params">
                <datepicker :inline="true" v-model="date" :disabledDates="disabledDates" @input="selectedDate"></datepicker>
              </b-col>
              <b-col>
                <h5 class="HowLong" style="float: left">Meeting duration</h5>
                <br>
                <br>
                <b-row>
                    <b-col cols="md-12">
                      <b-button :disabled="date === null || date === ''" class="duree-meeting" squared variant="info"
                      v-for="(time,index) in durationArray" :key="index" @click="displayTime(time)"
                      :class="{ 'selected-button': isSelected(time) }">{{time}} min</b-button>
                    </b-col>
                </b-row>
                <br />
                <h5 style="float: left">Available time</h5>
                <br />
                <br />
                <b-row class="available-time-meetings">
                  <b-col cols="12" class="meeting-time-proposition" v-if="meetings.times">
                    <b-button class="btn-available-time-meeting duree-meeting" squared style="width: 85%;"
                    @click="selectTime(time)" v-for="(time,index) in times" :key="index" >{{ time }}</b-button>
                  </b-col>
                  <b-col cols="12" v-else>There is no available time yet.</b-col>
                </b-row>
                <br />
                <div v-if="selectedmeeting">
                  <b-badge class="selectedmeet-badge" variant="success"
                    >Selected Timeline:
                    {{
                      selectedmeeting
                        ? this.dateFormat(selectedmeeting.date)
                        : "No Meeting selected"
                    }}</b-badge
                  >
                  <br /><br />
                </div>

              </b-col>
            </b-row>
            <b-card-footer>
              <b-button class="btn-validate" pill :disabled="validate" v-b-modal="'userInfo'"
                >Validate the meeting</b-button
              >
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="userInfo" centered title="User Info" @ok="submit" v-if="showModal">
      <b-row>
        <b-col md="12">
          <b-form-group label="" label-for="name" invalid-feedback="Name is required" :state="state1">
            <h5>Name</h5>
            <b-form-input id="name" placeholder="Enter name" v-model="invitation.name" required :state="state1"/>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="" label-for="email" invalid-feedback="Email is required" :state="state2">
            <h5>Email</h5>
            <b-form-input id="email" placeholder="Enter email" v-model="invitation.email" required :state="state2"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
import MeetingSelector from "../components/meeting-selector/Meeting-selectorCard.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BDropdown,
  BDropdownItem,
  BForm,
  BMedia,
  BAvatar,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCard,
  BCardGroup,
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCardFooter,
  BBadge,
} from "bootstrap-vue";

export default {
  data() {
    return {
      invitation : {
        /*user : JSON.parse(localStorage.getItem("userData"))._id,*/
        name : "",
        email : "",
        meeting : this.$route.query.id,
        duration : null,
        selectedDate : null,
        time : null
      },
      durationArray:[],
      selectedDuration: null,
      meetings:{},
      date : "",
      disabledDates: {},
      assignee: [],
      selectedmeeting: null,
      isNameValid: true,
      isEmailValid: true,
      showModal: true,
      firstDate: null,
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      times: [],
      fullTimes: [],
      options: [
        { text: "Sun", value: "Sunday" },
        { text: "Mon", value: "Monday" },
        { text: "Tue", value: "Tuesday" },
        { text: "Wed", value: "Wednesday" },
        { text: "Thu", value: "Thursday" },
        { text: "Fri", value: "Friday" },
        { text: "Sat", value: "Saturday" },
      ],
    };
  },
  setup() {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.userData.avatar = base64;
      }
    );

    return {
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  components: {
    Datepicker,

    BCardActions,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BForm,
    BAvatar,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BCardGroup,
    BMedia,
    MeetingSelector,
    BRow,
    BCol,
    BContainer,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
    BBadge,
  },
  created() {
    this.userData=JSON.parse(localStorage.getItem("userData"))
    axios.get("https://backendapinodejs.timecheckit.com/api/meetings?id=" + this.$route.query.id)
    .then((response) => {
      this.meetings=response.data.data
      this.durationArray = this.meetings.duration
      this.selectedDuration = this.durationArray[0]
      var selecDays = []
      if (this.meetings.times) {
        this.days.forEach((dayName, index) => {
          if(!this.meetings.times[dayName]) {
            selecDays.push(index)
          }
        })
      }
      this.disabledDates = {
        to : new Date(this.meetings.startDate),
        from : new Date(this.meetings.endDate),
        days: selecDays
      }
      this.setDate()
    })
    .catch((error) => {
      this.errorMessage = error.message;
    });
  },
  computed: {
    validate() {
      return (this.invitation.duration === null && this.invitation.selectedDate === null && this.invitation.time === null)
    },
    state1() {
      return this.isNameValid ? null : false;
    },
    state2() {
      return this.isEmailValid ? null : false;
    }
  },
  mounted() {},
  methods: {
    setDate() {
      const currentDate = new Date(this.meetings.startDate)
      const lastDate = new Date(this.meetings.endDate)
      while (currentDate <= lastDate) {
        if (!this.disabledDates.days.includes(currentDate.getDay())) {
          this.firstDate = new Date(currentDate);
          break;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.date = this.firstDate
      this.selectedDate()
    },
    isSelected(time) {
      return time === this.selectedDuration && this.date !== null && this.date !== ''
    },
    displayTime(time) {
      this.invitation.time = null
      this.selectedDuration = time
      this.selectedDate()
    },
    selectedDate() {
      if (this.meetings.times) {
        this.times = []
        this.verifDate();
        const day = this.date.toLocaleDateString('en-US', { weekday: 'long' })
        this.meetings.times[day].forEach(element => {
          const startDate = element.from;
          const endDate = element.to;
          let currentTime = startDate
          while (currentTime < endDate) {
            this.times.push(currentTime)
            currentTime = this.addDurationToTime(currentTime, parseInt(this.selectedDuration));
          }
        })
        this.fullTimes = this.times
      }
    },
    verifDate() {
      axios.get("https://backendapinodejs.timecheckit.com/api/date?user=" + JSON.parse(localStorage.getItem("userData"))._id)
      .then((response) => {
        const date = moment.utc(this.date).format('YYYY-MM-DD')
        const unavailableTimes = response.data.dates.reduce((result, data) => {
                  if (this.isValidDate(data.startDate) && this.isValidDate(data.endDate)) {
                     const startDate = moment.utc(data.startDate).format('YYYY-MM-DD');
                     const endDate = moment.utc(data.endDate).format('YYYY-MM-DD');
                     const startTime = moment.utc(data.startDate).format('HH:mm');
                     const endTime = moment.utc(data.endDate).format('HH:mm');
                     if (moment(date).isBetween(startDate, endDate, null, '[]')) {
                        result.push({
                           start : startTime,
                           end : endTime
                        })
                     }
                  }
                  return result;
               }, []);
              if (unavailableTimes.length!==0) {
                const filteredTimes = this.fullTimes.filter(time => {
                  return !unavailableTimes.some(obj => {
                    const startTime = obj.start;
                    const endTime = obj.end;
                    return time >= startTime && time < endTime;
                  });
                });
                this.times = filteredTimes
              }
              else {
                  this.times = this.fullTimes
               }
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
    },
    isValidDate(dateString) {
      const date = new Date(dateString);
      return !isNaN(date) && date instanceof Date && !isNaN(date.getTime()) && dateString.includes('T');
    },
    addDurationToTime(time, duration) {
      const [hours, minutes] = time.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes + duration;
      const newHours = Math.floor(totalMinutes / 60);
      const newMinutes = totalMinutes % 60;  
      // Format the new time as HH:mm
      const formattedTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;    
      return formattedTime;
    },
    selectTime(time) {
      this.invitation.time = time
    },
    validateMeeting() {
      this.invitation.duration = this.selectedDuration
      this.invitation.selectedDate = moment(this.date).format('YYYY-MM-DD')
      axios.post("https://backendapinodejs.timecheckit.com/api/meetingInvitation",this.invitation)
          .then((response) => {
            this.showModal = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invitation added",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            console.log(error)
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "error during action",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
    },
    submit(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.invitation.name.trim() === '') {
        this.isNameValid = false;
      } else {
        this.isNameValid = true;
      }
      if (this.invitation.email.trim() === '') {
        this.isEmailValid = false;
      } else {
        this.isEmailValid = true;
      }
      if (this.isNameValid && this.isEmailValid) {
        this.validateMeeting()
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// scoped
.mettings-preview {
  min-height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mettings .card .card-header {
  border-bottom: none !important;
  padding: 1rem !important;
  background: linear-gradient(
    118deg,
    #9600ff,
    rgba(115, 103, 240, 0.7)
  ) !important;
  color: white !important;
}
.mettings .card-body {
  padding-top: 5% !important;
}
.duree-meeting {
  border-color: #9600ff !important;
  background-color: rgba(115, 103, 240, 0.7) !important;
}
.duree-meeting:hover,
.duree-meeting:active,
.duree-meeting:focus,
.duree-meeting.selected-button {
  border-color: #9600ff !important;
  background-color: #9600ff !important;
}
label {
  color: #5e5873 !important;
  font-size: 1rem !important;
}
.meeting__button--selected {
  background-color: #00ee6b;
}

.meeting-btn {
  border-color: #5600ffc9 !important;
  background-color: #5600ffc9 !important;
}
.btn-validate {
  border-color: #5600ffc9 !important;
  background-color: #5600ffc9 !important;
}
.btn-validate:active {
  border-color: #5600ffc9 !important;
  background-color: #5600ffc9 !important;
}
.btn-validate:focus {
  border-color: #5600ffc9 !important;
  background-color: #5600ffc9 !important;
}
.selectedmeet-badge {
  padding: 1rem 0.5rem !important;
}

.vdp-datepicker__calendar {
  margin-left: 10%;
  border-radius: 20px;
  // border: 1px solid #9600ff;
}
.vdp-datepicker__calendar .cell.selected {
  background: #9600ff;
  color: #fff;
}
.vdp-datepicker__calendar header span {
  background: #9600ff;
  color: #fff;
}
.vdp-datepicker__calendar header span {
  background: #9600ff;
  color: #fff;
}
.vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid #fff;
}
.vdp-datepicker__calendar header .next:after {
  border-left: 10px solid #fff;
}
.available-time-meetings div button {
  margin-bottom: 2.5%;
}
.btn-available-time-meeting:hover {
  background-color: #9600ff !important;
}
.vdp-datepicker__calendar .cell.selected:hover{
  background: rgba(115, 103, 240, 0.7);
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover{
  border: 1px solid rgba(115, 103, 240, 0.7);;
}
.vdp-datepicker__calendar header .prev:not(.disabled):hover, .vdp-datepicker__calendar header .next:not(.disabled):hover, .vdp-datepicker__calendar header .up:not(.disabled):hover{
    background: #9600ff
}
@media (max-width: 360px) {
  .HowLong {
    padding-top: 15px;
  }
  .card-bigger {
    width: 105%;
  }
  .datepicker-params {
    padding-right: 350px;
    padding-left:0px;
  }
  .evr-centered {
    text-align: center;
  }
  .duree-meeting {
    padding: 8px;
  }
}
.meeting-time-proposition{
  height: 400px;
  overflow: scroll;
}
</style>
