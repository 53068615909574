<template>
  <div>
    <vue-meeting-selector
      v-model="meeting"
      :date="date"
      :loading="loading"
      :meetings-days="meetingsDays"
      @next-date="nextDate"
      @previous-date="previousDate"
      @change="onChange()"
    />

  </div>
</template>

<script>
import VueMeetingSelector from "vue-meeting-selector";

export default {
  components: {
    VueMeetingSelector,
  },
  data() {
    return {
      date: new Date("2020-01-01:01:00"),
      meeting: null,
      loading: true,
      meetingsDays: [],
    };
  },

  methods: {
    dateFormat: function (value) {
      return new Date(value).toLocaleString();
    },
    onChange() {
      // console.log("testing component",this.meeting);
      this.$emit("change", this.meeting);
    },
    getMeetings(date) {
      return [
        // Lundi
        {
          date: "2021-07-12T00:00:00.000Z",
          slots: [
            {
              date: "2021-07-12T08:30:00.000Z",
            },
            {
              date: "2021-07-12T09:00:00.000Z",
            },
            {
              date: "2021-07-12T09:30:00.000Z",
            },
            {
              date: "2021-07-12T10:00:00.000Z",
            },
            {
              date: "2021-07-12T10:30:00.000Z",
            },
            {
              date: "2021-07-12T11:00:00.000Z",
            },
            {
              date: "2021-07-12T11:30:00.000Z",
            },
          ],
        },
        // Mardi
        {
          date: "2021-07-13T00:00:00.000Z",
          slots: [
            {
              date: "2021-07-13T08:30:00.000Z",
            },
            {
              date: "2021-07-13T09:00:00.000Z",
            },
            {
              date: "2021-07-13T09:30:00.000Z",
            },
            {
              date: "2021-07-13T10:00:00.000Z",
            },
            {
              date: "2021-07-13T10:30:00.000Z",
            },
            {
              date: "2021-07-13T11:00:00.000Z",
            },
            {
              date: "2021-07-13T11:30:00.000Z",
            },
          ],
        },
        // Mercredi
        {
          date: "2021-07-14T00:00:00.000Z",
          slots: [
            {
              date: "2021-07-14T06:00:00.000Z",
            },
            {
              date: "2021-07-14T06:30:00.000Z",
            },
            {
              date: "2021-07-14T07:00:00.000Z",
            },
            {
              date: "2021-07-14T07:30:00.000Z",
            },
            {
              date: "2021-07-14T08:00:00.000Z",
            },
            {
              date: "2021-07-14T08:30:00.000Z",
            },
          ],
        },
        // Jeudi
        {
          date: "2021-07-15T00:00:00.000Z",
          slots: [
            {
              date: "2021-07-15T06:00:00.000Z",
            },
            {
              date: "2021-07-15T06:30:00.000Z",
            },
            {
              date: "2021-07-15T07:00:00.000Z",
            },
            {
              date: "2021-07-15T07:30:00.000Z",
            },
            {
              date: "2021-07-15T08:00:00.000Z",
            },
            {
              date: "2021-07-15T08:30:00.000Z",
            },
          ],
        },
        // Vendredi
        {
          date: "2021-07-16T00:00:00.000Z",
          slots: [
            {
              date: "2021-07-16T06:00:00.000Z",
            },
            {
              date: "2021-07-16T06:30:00.000Z",
            },
            {
              date: "2021-07-16T07:00:00.000Z",
            },
            {
              date: "2021-07-16T07:30:00.000Z",
            },
            {
              date: "2021-07-16T08:00:00.000Z",
            },
            {
              date: "2021-07-16T08:30:00.000Z",
            },
          ],
        },
      ];
    },
    async nextDate() {
      this.loading = true;
      const date = new Date(this.date);
      date.setDate(date.getDate() + 7);
      this.meetingsDays = await this.getMeetings(date);
      this.date = date;
      this.loading = false;
    },
    async previousDate() {
      this.loading = true;
      const date = new Date(this.date);
      date.setDate(date.getDate() - 7);
      this.meetingsDays = await this.getMeetings(date);
      this.date = date;
      this.loading = false;
    },
  },
  async created() {
    console.log(this.dateFormat("2021-07-12T00:00:00.000Z"));
    this.loading = true;
    this.meetingsDays = await this.getMeetings(this.date);
    this.loading = false;
  },
};
</script>
<style scoped>
.meeting__button--selected {
  background-color: #12ef93 !important;
}
</style>
